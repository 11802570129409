import { Fragment } from "react";

function Featured() {
  return <Fragment>
    <div className="featured-area">
      <div className="blockcain-and-featured-area">
        <section className="blockcain-top-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-duration="1.5s">
                <div className="blockcain-top">
                  <h2 className="column-title">Our Vision
                  </h2>
                </div>
              </div>
              <div className="col-md-5 offset-md-1 wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-top-content">
                  <p>
                    To provide fit for purpose and dynamic payments solutions that addresses domestic and international
                    needs of our partners and their customers, supporting innovations requirements,  aims and aspirations
                    of our stakeholders in an ever evolving payments sector.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>




        <section className="blockcain-business-sec" id="featured">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6 wow fadeInUp my-auto" data-wow-duration="1.5s">
                <div className="blockcain-img">
                  <img src="assets/images/aboutus/aboutdssc2.png" alt="" />
                </div>
              </div>
              <div className="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-content">
                  <small className="xs-section-title">About US</small>
                  <h3 className="column-title">About DSSC</h3>
                  <p>DSSC develops white label payments and financial services technologies that features key and required elements of compliance, data protection and AI.</p>
                  <p>DSSC is a payments technology developer and service provider providing state of the art solutions for the authorised sector.</p>
                  <p>DSSC supports safe and compliant interconnection of payments transactions, financial products and services, systems and products aggregation and efficient processes.</p>
                  <p>DSSC supports  our partners to facilitate faster, cheaper and more accessible movement of money within and across different countries, networks and currencies.</p>
                  <p>DSSC supports our partners businesses to create new and sustainable revenue streams by connecting them to our network of corridors, products and services  allowing for the digital interconnection of businesses, products and services  worldwide.</p>
                  <p>DSSC partners benefit (in country) from DSSC’s domestic financial services digital solutions and products, designed to support e- wallets, Micro Core Banking and ecommerce.</p>
                  <p>DSSC was founded in 2016.</p>
                  <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="blockcain-top-sec">
          <div className="container">
            <div>
              <div className=" wow fadeInUp" data-wow-duration="1.5s">
                <div className="blockcain-top text-center">
                  <h2 className="column-title">OUR VALUE TO YOU...
                  </h2>
                </div>
              </div>
              <div className="wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-top-content">
                  <ul className="bullet" >
                    <p>Our  ability to move funds from senders to receivers, both domestic and international, in real-time at low cost using our superior payment technology. </p>
                    <p>Our  platform is flexible, portable and scalable.</p>
                    <p>Our  transactions are processed instantaneously at low cost on the payment platform, eliminating any need for manual processing and with immediate forex convertibility.</p>
                    <p>Our partners do not need a bank account or a Visa card.</p>
                    <p>Our  platform is built around financial and payments services, compliance, reporting, marketing and data protection. </p>
                    <p>Our  business is focused on supporting our partners to deliver services, products and value to their customers in a fast, reliable and compliant way.</p>
                    <p>Our platform is bespoke and is easily customizable to handle the smallest movement of funds all the way through to the aggregation of country-wide financial transactions via our national switch infrastructures.</p>
                    <p>Our  technology continues to be developed and matured by a team that is highly experienced across mobile financial services, remittance, payments, compliance and sale of goods.</p>
                    <p>Our  partners’ needs priority approach. </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="featured-sec pt-5" >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="section-title-item wow fadeInUp" data-wow-duration="1.5s">
                  <small className="xs-section-title">OUR OFFICES</small>
                  <h2 className="section-title">DSSC BRANCH OFFICES</h2>
                </div>
                <div className="text-center wow fadeInUp" data-wow-duration="2s">
                  <img className="d-inline" src="assets/images/aboutus/worldmap.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>



        <section className="blockcain-top-sec">
          <div className="container">
            <div>
              <div className=" wow fadeInUp" data-wow-duration="1.5s">
                <div className="blockcain-top text-center">
                  <h2 className="column-title">WHAT MAKES US UNIQUE?
                  </h2>
                </div>
              </div>
              <div className="wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-top-content">
                  <ul className="bullet" >
                    <p>The uniqueness of the DSSC platform is its ability to move funds from a sender to a receiver, both domestic and international, in real-time at low cost using standard payment technology. This makes the platform flexible, portable and scalable.</p>
                    <p>All the DSSC transactions are processed instantaneously at low cost on the payment's platform, eliminating any need for manual processing and with immediate forex convertibility.</p>
                    <p>The customer does not need a bank account or a Visa card.</p>
                    <p>The DSSC platform is built around financial services, state-of-the-art compliance, reporting, marketing and data protection. (Data protection measures resist internal and external disturbances and attacks on critical data and systems at large)</p>
                    <p>The DSSC business is focused on services, it requires presence on the ground and extensive knowledge of the markets that we operate in – as well as the trust of the key operators in these markets.</p>
                    <p>The DSSC platform is designed to be bespoke and is easily customizable to handle the smallest movement of funds, all the way through to the aggregation of country-wide financial transactions via national switch infrastructures.</p>
                    <p>The technology continues to be developed and matured by a team that is highly experienced across mobile financial services, remittance, payments, compliance and regulatory sectors.</p>
                    <p>With the core financial solution, what differentiates us from larger competitors is our approach. Larger companies rely on their existing network of customers for their national payments products and effectively impose their own methods of payment on them. Our approach is the ”outside-in approach” that provide the exact solution the customer want</p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="banner-sec col-lg-10 mx-auto" id="particles-js1">
          <div className="banner-item">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="banner-content wow fadeInUp" data-wow-duration="2s">
                    <h1 className="banner-title">DSSC Network</h1>
                  </div>
                </div>
              </div>
              <div className="banner-list wow fadeInUp" data-wow-duration="2s">
                <img className="banner-img opacity-map" src="assets/images/aboutus/worldimagenetwork.png" alt="Paymenta" />
                <div className="banner-list banner-list-1">
                  <ul className="bullet white">
                    <li>Toronto</li>
                    <li>Vancouver</li>
                    <li>Montreal</li>

                  </ul>
                </div>
                <div className="banner-list banner-list-2 wow fadeInUp" data-wow-duration="2s">
                  <div className="row">
                    <div className="col">
                      <ul className="bullet white">
                        <li><div>Benin</div></li>
                        <li><div>Botswana</div></li>
                        <li><div>Burkina Faso</div></li>
                        <li><div>Burundi</div></li>
                        <li><div>Cameroon</div></li>
                        <li><div>Ethiopia</div></li>
                        <li><div>DRC(Congo)</div></li>
                        <li><div>Ivory Coast</div></li>
                        <li><div>Gabon</div></li>
                        <li><div>Ghana</div></li>
                        <li><div>Guinea Bissau</div></li>
                        <li><div>Guinea-Conakry</div></li>
                        <li><div>Kenya</div></li>
                        <li><div>Lesotho</div></li>
                        <li><div>Liberia</div></li>
                        <li><div>Madagascar</div></li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="bullet white">
                        <li><div>Malawi</div></li>
                        <li><div>Mali</div></li>
                        <li><div>Mozambique</div></li>
                        <li><div>Niger</div></li>
                        <li><div>Nigeria</div></li>
                        <li><div>RCA</div></li>
                        <li><div>Rwanda</div></li>
                        <li><div>Senegal</div></li>
                        <li><div>Tanzania</div></li>
                        <li><div>Togo</div></li>
                        <li><div>Uganda</div></li>
                        <li><div>Tchad</div></li>
                        <li><div>Zambia</div></li>
                        <li><div>Zimbabwe</div></li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="bullet white">
                        <li><div>Somalia</div></li>
                        <li><div>Djibouti</div></li>
                        <li><div>Jordan</div></li>
                        <li><div>Morocco</div></li>
                        <li><div>Lebanon</div></li>
                        <li><div>Yemen</div></li>
                        <li><div>Turkey</div></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="banner-list banner-list-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="row">
                    <div className="col">

                      <ul className="bullet white">
                        <li>Azerbaijan</li>
                        <li>Armenia</li>
                        <li>Belarus</li>
                        <li>Georgia</li>
                        <li>Kazakhstan</li>
                        <li>Kyrgyzstan</li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="bullet">
                        <li>MoldovaCIS</li>
                        <li>Russia</li>
                        <li>Tajikistan</li>
                        <li>Turkmenistan</li>
                        <li>Uzbekistan</li>
                        <li>Ukraine</li>
                      </ul>
                    </div>
                  </div>

                </div>
                <div className="banner-list banner-list-5 wow fadeInUp" data-wow-duration="2s">
                  <ul className="bullet white">
                    <li>Pakistan</li>
                    <li>India</li>
                    <li>Srilanka</li>
                    <li>Bangladesh</li>
                    <li>Phillippines</li>
                    <li>Indonesia</li>
                    <li>Vietnam</li>
                    <li>Nepal</li>
                    <li>South Korea</li>
                    <li>Malaysia</li>
                    <li>Hong Kong</li>
                    <li>Thailand</li>
                    <li>China</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>


















      <div className="blockcain-and-logo-area">
        <section className=" section-padding" id="how_work">
          <div className="container">
            <div className="row">

              <div className="col-md-6 col-lg-6 wow fadeInUp my-auto" data-wow-duration="1.5s">
                <div className="blockcain-img ">
                  <img src="assets/images/products/switch.png" alt="" />
                </div>
              </div>

              <div className="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-content">
                  <small className="xs-section-title">Products</small>
                  <h3 className="column-title">CORE FINANCIAL SWITCH</h3>
                  <p>
                    A payment switch that is designed as an interoperability and aggregation platform for domestic and international payment transactions, connecting:
                  </p>
                  <div className="row">
                    <div className="col">
                      <ul className="bullet">
                        <li><div>International Authorised Service Sector Providers</div></li>
                        <li><div>ATMs</div></li>
                        <li><div>Macro and micro finance companies</div></li>
                        <li><div>Payment's services providers</div></li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="bullet">
                        <li><div>MTOs</div></li>
                        <li><div>Financial Insitutions</div></li>
                      </ul>
                    </div>

                  </div>

                  <p>
                    Allowing for real-time, 24x7 safe and compliant financial transactions processing between all connected entities
                  </p>

                  <p>
                    Incluswif is a DSSC proprietary solution designed to serve and support the needs of our partner and, working as the core financial and payment processing switch and, by design, it is an interoperability platform, connecting Governments, Financial & Payments services providers, ATMs, MTOs, Financial Institutions and other designated authorized entities and services;
                    The system enables  the  efficient and effective delivery of accessible digital finance services essential for supporting the drive for financial and social inclusion among the excluded masses.
                  </p>
                  <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                </div>
              </div>



            </div>
          </div>
        </section>



        <section className="section-padding">
          <div className="container">
            <div className="row flex-column-reverse flex-md-row">

              <div className="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-content">
                  <small className="xs-section-title">Products</small>
                  <h3 className="column-title">REMITTANCE ECO SYSTEM</h3>
                  <p>
                    The DSSC remittance platform is split into two payment solutions:
                  </p>
                  <p>
                    <b>An International aggregator hub</b>, that hosts our authorised payments and  remittance service provider partners and enables value drive interaction between them.
                  </p>
                  <p>
                    <b>Service Provider Solution</b>, that is a dynamic stand-alone domestic remittance solution, provided to our authorised partners enabling them to deliver for their customers whilst supporting, commercial, market and regulated needs.
                  </p>
                  {/* <p>
                    Within our pay-in network we are connecting to multiple licensed payment institutions which already have customers sending money to the various countries DSSC is connected to.
                  </p>
                  <p>
                    If a licensed financial institution does not have the technology, DSSC provides a full white label product suite to enable them to immediately start rolling out the mobile applications to their customer and start remitting. To conclude these solutions, we have a settlement engine that provides all the financial statistics that can do daily, weekly and monthly reconciliation.
                  </p> */}
                  <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                </div>
              </div>


              <div className="col-md-6 col-lg-6 wow fadeInUp my-auto " data-wow-duration="1.5s">
                <div className="blockcain-img ">
                  <img src="assets/images/products/remittance.png" alt="Remittance" className="remittance_img" />
                </div>
              </div>



            </div>
          </div>
        </section>



        <section className="section-padding">
          <div className="container">
            <div className="row">


              <div className="col-md-6 col-lg-6 wow fadeInUp my-auto" data-wow-duration="1.5s">
                <div className="blockcain-img ">
                  <img src="assets/images/products/wallet2.png" alt="Wallet" className="ewallet_img" />
                </div>
              </div>

              <div className="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-content">
                  <small className="xs-section-title">Products</small>
                  <h3 className="column-title">E-WALLET ECOSYSTEM</h3>
                  <p>
                    DSSC offers a white-label mobile wallet solution where virtually any domestic or international financial service can be conducted through the wallet and its connectivity to the DSSC global network.
                  </p>

                  <p>Our digital wallet provides our partners with some key features </p>

                  <ul className="bullet">

                    <li><div>Agent Management module including a mobile or tablet front end application</div></li>
                    <li><div>POS/KIOSK as branches for agent to top-up and cash-out for traders</div></li>
                    <li><div>White-label branded end user mobile app to conduct day-to-day financial transactions</div></li>
                    <li><div>Reporting suite for regulatory/compliance as well as finance departments</div></li>
                    <li><div>Flexible deployment schemes (Cloud or on- premise) depending on your market size/needs</div></li>
                    <li><div>Treasury management system which allows for single or multi-currency and the management of exchange rates</div></li>
                    <li><div>Training for operations and back-office staff</div></li>
                    <li><div>KYC engine which allows electronic document capture and verification</div></li>
                    <li><div>Open-loop or Closed Loop wallet solution</div></li>
                    <li><div>AML &amp; Fraud management in line with both local and international compliance</div></li>
                    <li><div>Multi-tiered accounts (silver, gold, platinum) for your customers</div></li>


                  </ul>



                  <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                </div>
              </div>






            </div>
          </div>
        </section>



        {/* <section className="section-padding">
          <div className="container text-center wow fadeInUp " data-wow-duration="2s">

            <div className="offset-lg-1 pb-5" >
              <div className="blockcain-content">
                <small className="xs-section-title">Products</small>
                <h3 className="column-title">E-WALLET ECOSYSTEM</h3>
              </div>
            </div>

            <div className="wow fadeInUp pb-5 " data-wow-duration="1.5s">
              <div >
                <img src="assets/images/products/wallet.png" alt="" />
              </div>
            </div>


            <div className="offset-lg-1 wow fadeInUp" data-wow-duration="2s">
              <div className="blockcain-content">
                <p>
                  DSSC offers a white-label mobile wallet solution where virtually any financial service can be conducted both locally and internationally through its connectivity to the DSSC global network.
                </p>

                <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
              </div>
            </div>


          </div>
        </section> */}


        <section className="section-padding">
          <div className="container">
            <div className="row flex-column-reverse flex-md-row">




              <div className="col-md-4 col-lg-4  wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-content">
                  <small className="xs-section-title">Products</small>
                  <h3 className="column-title">Know-Your-Customer</h3>
                  <p>
                    KYC solution allows admin users to confirm KYC requests from wallet users via mobile or web and POS users (agents) to initiate KYC procedures. Wallet users must have their KYC process verified before they can start sending and receiving money
                  </p>

                  <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                </div>
              </div>


              <div className="col-md-8 col-lg-8 wow fadeInUp my-auto" data-wow-duration="1.5s">
                <div className="blockcain-img ">
                  <img src="assets/images/products/kyc.png" alt="KYC" className="kyc_img" />
                </div>
              </div>


            </div>
          </div>
        </section>





        <section className=" section-padding">
          <div className="container">
            <div className="row">

              <div className="col-md-6 col-lg-5 offset-lg-1 wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-content">
                  <small className="xs-section-title">Products</small>
                  <h3 className="column-title">TREASURY </h3>
                  <h4>Main Features – Fees, Fx and Mark-up’s</h4>
                  <p>
                    Treasury features includes:
                  </p>
                  <div className="pb-3">
                    <ul className="bullet">
                      <li><div>Fees management</div></li>
                      <li><div>Fx management</div></li>
                      <li><div>Settlement and Reconciliation reports extraction</div></li>
                    </ul>

                  </div>


                  <p>
                    These attributes can be set by admin users and they will be applied accordingly to the time set
                    defined to the users transactions on the platform.
                  </p>

                  <p>
                    e.g. For a given top-up of a USD Wallet a fee of 3 USD would be charged and a FX of 1.15 will
                    be set (Being the market FX of 1.2 the fx gain would be 0.05)
                  </p>
                  <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 wow fadeInUp my-auto" data-wow-duration="1.5s">
                <div className="blockcain-img ">
                  <img src="assets/images/products/treasury.png" alt="Treasury" className="treasury_img" />
                </div>
              </div>

            </div>
          </div>
        </section>


        <section className="blockcain-top-sec">
          <div className="container">
            <div>
              <div className=" wow fadeInUp" data-wow-duration="1.5s">
                <div className="blockcain-top text-center">
                  <h2 className="column-title">Services
                  </h2>
                </div>
              </div>
              <div className="wow fadeInUp" data-wow-duration="2s">
                <div className="blockcain-top-content">
                  <ul className="bullet" >
                    <p>Get all international remittance corridors on our Aggregator platform and enjoy the  vast payout network that continues to grow as we enter new countries. </p>
                    <p>Partnership with financial institutions, mobile money operators, MNO’s, international remittance service providers.</p>
                    <p>Fit for purpose digital compliance solution support</p>
                    <p>Facilitation of value adding relationships with authorised support services providers </p>
                    <p>Ongoing assessment and proactive sharing of market trends to support service delivery and customer satisfaction</p>
                    <p>A ready  connection to multiple live and transacting licensed payment institutions.</p>
                    <p>Provision  of a full white label product suite to enable our partners immediately start rolling out their digital financial services to their customer. </p>
                    <p>Real time financial statistics via our InterMap treasury solution for daily, weekly and monthly reconciliation.</p>
                    <p>Training, Support and Guidance on systems, models and regulatory requirements</p>
                    <p>Introduction and partnership facilitation with key sector services providers </p>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="featured-sec" id="featured">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="section-title-item">
                  <h2 className="section-title">Partners</h2>
                </div>
              </div>
            </div>{/* row end */}
            <div className="main-fetured-item">
              <div className="row">
                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/kegow.png" alt="Kegow" className="rounded" />
                  </div>
                </div>{/* col end */}
                <div className="col-md-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/guulpay.png" alt="GuulPay" className="rounded" />

                  </div>
                </div>{/* col end */}
                <div className="col-md-4 wow fadeInUp" data-wow-duration="2.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/bsystems.png" alt="BSystems" className="rounded" />
                  </div>
                </div>{/* col end */}
              </div>{/* row end */}

              <div className="row">

                <div className="col-md-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/amal.png" alt="Amal Express" className="rounded" />

                  </div>
                </div>{/* col end */}
                <div className="col-md-4 wow fadeInUp" data-wow-duration="2.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/upt.png" alt="UPT" className="rounded" />
                  </div>
                </div>{/* col end */}

                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/payquick.png" alt="PayQuik" className="rounded" />
                  </div>
                </div>{/* col end */}

              </div>{/* row end */}

              <div className="row">

                <div className="col-md-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/nationtransfer.png" alt="NationTransfer" className="rounded" />

                  </div>
                </div>{/* col end */}



                <div className="col-md-4 wow fadeInUp" data-wow-duration="2.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/supersonicz.png" alt="Supersonicz" className="rounded" />
                  </div>
                </div>{/* col end */}

                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/tawakal.png" alt="Tawakal" className="rounded" />
                  </div>
                </div>{/* col end */}


              </div>{/* row end */}

              <div className="row">

                <div className="col-md-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/bunna.png" alt="Bunna" className="rounded" />

                  </div>
                </div>{/* col end */}



                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/banana.png" alt="Banana Pay" className="rounded" />
                  </div>
                </div>{/* col end */}




                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/reliance.png" alt="Banana Pay" className="rounded" />
                  </div>
                </div>{/* col end */}


              </div>{/* row end */}


              <div className="row">


                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/gamswitch.png" alt="Banana Pay" className="rounded" />
                  </div>
                </div>{/* col end */}



                <div className="col-md-4 wow fadeInUp" data-wow-duration="2s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/bancore.png" alt="Bunna" className="rounded" />

                  </div>
                </div>{/* col end */}



                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/qmoney.png" alt="Banana Pay" className="rounded" />
                  </div>
                </div>{/* col end */}

              </div>{/* row end */}

              <div className="row">

                <div className="col-md-4 wow fadeInUp" data-wow-duration="1.5s">
                  <div className="single-feaured-item">
                    <img src="assets/images/partners/swiftpayment.png" alt="Banana Pay" className="rounded" />
                  </div>
                </div>{/* col end */}



              </div>{/* row end */}

            </div>{/* main-fetured-item end */}

          </div>
        </section>

        <section className="client-logo-sec">
          <div className="container">
            <div className="client-logo-item">
              <div className="row owl-carousel" id="client-slider">
                &nbsp;
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Fragment>
}

export default Featured
