import React from 'react';
import { Fragment, useState } from "react";
import axios from 'axios';
import qs from 'qs';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function DocumentTeam() {

  let [responseData, setResponseData] = useState('');
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [message, setMessage] = useState();
  const [modal, setModal] = useState(false);

  const toggle = () => {

    let newToggleState = !modal

    setModal(newToggleState)
    if(!newToggleState){
      setName("")
      setEmail("")
      setMessage("")
      setMobile("")
    }
  };

  const fetchData = React.useCallback((params) => {
    console.log('params', params)
    const data = params
    axios.post(process.env.REACT_APP_INQUIRE, qs.stringify(data))
      .then(function (response) {
        console.log(response);
        setResponseData(response.data)
        toggle()
      })
      .catch(function (error) {
        console.log(error);
      });


  }, [])

  const handleSubmit = (e) => {
    console.log('data sending', name, mobile, email, message)
    let params = {
      name,
      mobile,
      email,
      message
    }
    fetchData(params)
    e.preventDefault();
  }

  // React.useEffect(() => {
  //   fetchData()
  // }, [fetchData])

  

  return <Fragment>
    <div>

      <section className="team-sec section-padding" id="contact_us">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center mb-5">
              <h2 className="heading-section">Contact Us</h2>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="wrapper">
                <div className="row justify-content-center">
                  <div className="col-lg-8 mb-5">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="dbox w-100 text-center">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="fa fa-map-marker"></span>
                          </div>
                          <div className="text">
                            <p><span>Address:</span><br /> 2202b South Emirates Financial Towers, DIFC Dubai, United Arab Emirates
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dbox w-100 text-center">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="fa fa-phone"></span>
                          </div>
                          <div className="text">
                            <p><span>Phone:</span> <br /> <a href="tel://1234567920">+971 42 861 797</a></p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dbox w-100 text-center">
                          <div className="icon d-flex align-items-center justify-content-center">
                            <span className="fa fa-paper-plane"></span>
                          </div>
                          <div className="text">
                            <p><span>Email:</span> <br /> <a href="mailto:support@dssctech.com">support@dssctech.com</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="contact-wrap">
                      <h3 className="mb-4 text-center">Get in touch with us</h3>
                      <form method="POST" onSubmit={e => { handleSubmit(e) }}  id="contactForm" name="contactForm" className="contactForm" noValidate="novalidate">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} name="name" id="name" placeholder="Name" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="text" className="form-control" value={mobile} onChange={(e) => setMobile(e.target.value)} name="mobile" id="mobile" placeholder="Phone/Mobile" />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} name="email" id="email" placeholder="Email" />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea name="message" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} id="message" cols="30" rows="8" placeholder="Message"></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="submit" value="Send Message" className="btn btn-primary" />
                              <div className="submitting"></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>


      {/* <section className="team-sec section-padding" id="team">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8 mx-auto">
                      <div className="section-title-item">
                        <small className="xs-section-title">Our experts</small>
                        <h2 className="section-title">Team Members</h2>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="1.5s">
                      <div className="single-team">
                        <div className="team-img">
                          <img src="assets/images/team/team_1.jpg" alt="" />
                          <div className="team-social">
                            <a href="#">
                              <i className="icon icon-facebook" />
                            </a>
                            <a href="#">
                              <i className="icon icon-twitter" />
                            </a>
                            <a href="#">
                              <i className="icon icon-linkedin" />
                            </a>
                          </div>
                        </div>
                        <div className="team-content">
                          <h3 className="xs-title md">James Carter</h3>
                          <p>CEO</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="2s">
                      <div className="single-team">
                        <div className="team-img">
                          <img src="assets/images/team/team_2.jpg" alt="" />
                          <div className="team-social">
                            <a href="#">
                              <i className="icon icon-facebook" />
                            </a>
                            <a href="#">
                              <i className="icon icon-twitter" />
                            </a>
                            <a href="#">
                              <i className="icon icon-linkedin" />
                            </a>
                          </div>
                        </div>
                        <div className="team-content">
                          <h3 className="xs-title md">Jenifar Lorany</h3>
                          <p>Marketing Head</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="2.5s">
                      <div className="single-team">
                        <div className="team-img">
                          <img src="assets/images/team/team_3.jpg" alt="" />
                          <div className="team-social">
                            <a href="#">
                              <i className="icon icon-facebook" />
                            </a>
                            <a href="#">
                              <i className="icon icon-twitter" />
                            </a>
                            <a href="#">
                              <i className="icon icon-linkedin" />
                            </a>
                          </div>
                        </div>
                        <div className="team-content">
                          <h3 className="xs-title md">Michel Ramos</h3>
                          <p>HR Head</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-duration="3s">
                      <div className="single-team">
                        <div className="team-img">
                          <img src="assets/images/team/team_4.jpg" alt="" />
                          <div className="team-social">
                            <a href="#">
                              <i className="icon icon-facebook" />
                            </a>
                            <a href="#">
                              <i className="icon icon-twitter" />
                            </a>
                            <a href="#">
                              <i className="icon icon-linkedin" />
                            </a>
                          </div>
                        </div>
                        <div className="team-content">
                          <h3 className="xs-title md">Lorence Katie</h3>
                          <p>Lead Designer</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section> */}
    </div>

    <Modal isOpen={modal} toggle={toggle} >
        <ModalHeader toggle={toggle}><span className="text-primary">Sending Successful</span></ModalHeader>
        <ModalBody>
          You're inquiry has been sent.  We will contact you shortly.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>Dissmiss</Button>{' '}
        </ModalFooter>
      </Modal>
  </Fragment>
}

export default DocumentTeam