import { Fragment } from "react";

function Header() {
    return <Fragment>
        <header className="header">
            <div className="container">
                <nav className="navigation navbar navbar-expand-lg navwhite">
                    <div className="nav-header">
                        <a className="nav-brand" href="index.html">
                            <img src="assets/images/logo_light3.png" alt="" />
                        </a>
                        {/* <div className="nav-toggle" /> */}
                    </div>
                    <button className="navbar-toggler navbar-dark float-right mt-3" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon " />
                    </button>

                    <div className="nav-menus-wrapper xs-menu collapse navbar-collapse mt-xs-5 float-right" id="navbarText">
                        <ul className="nav-menu align-to-right">
                            <li className="active">
                                <a href="#">Home</a>
                            </li>
                            <li><a href="#featured" className="scrolls">About Us</a></li>
                            <li><a href="#how_work" className="scrolls">Products</a></li>
                            <li><a href="#technology" className="scrolls">Technology</a></li>
                            <li><a href="#contact_us" className="scrolls">Contact Us</a></li>
                        </ul>
                    </div>
                </nav>

                {/* <nav className="navbar navbar-expand-lg navwhite">
                    <a className="nav-brand" href="index.html">
                        <img src="assets/images/logo_light3.png" alt="" />
                    </a>
              

                    <button className="navbar-toggler navbar-dark" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon " />
                    </button>
                    <div className="collapse navbar-collapse my-2 my-lg-0 float-right" id="navbarText">
                        <ul className="navbar-nav mr-auto ">
                            <li className="active">
                                <a href="#">Home</a>
                            </li>
                            <li><a href="#featured" className="scrolls">About Us</a></li>
                            <li><a href="#how_work" className="scrolls">Products</a></li>
                            <li><a href="#technology" className="scrolls">Technology</a></li>
                            <li><a href="#team" className="scrolls">Team</a></li>
                            <li><a href="#contact_us" className="scrolls">Contact Us</a></li>
                        </ul>
                    </div>
                </nav> */}

            </div>
        </header>
    </Fragment>
}

export default Header;