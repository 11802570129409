import { Fragment } from "react";


function HowWork() {
  return <Fragment>


    <div className="how-work-and-token-area">


      <section className="how-work-sec section-padding" id="technology">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-title-item">
                <small className="xs-section-title">Technology</small>
                <h2 className="section-title">PLATFORM FEATURES</h2>
              </div>
            </div>
          </div>
          <div className=" wow fadeInUp " data-wow-duration="1.5s">
            <div className="blockcain-img ">
              <img src="assets/images/products/platform.png" alt="" />
            </div>
          </div>
          <div className="text-center pt-5 align-self-center wow fadeInUp" data-wow-duration="2s">
            <p>
            The technology continues to be developed and matured by a team that has more than 60 years of combined experience across mobile financial services, remittance, payments, compliance and regulatory sectors.
            </p>
          </div>

        </div>
      </section>


      <section className="how-work-sec section-padding" >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="section-title-item">
                <small className="xs-section-title">Technology</small>
                <h2 className="section-title">SECURITY</h2>
              </div>
            </div>
          </div>


          <div className="text-center align-self-center wow fadeInUp" data-wow-duration="2s">
            <p>
            DSSC utilizes the Amazon Web Services(AWS) which are aligned and compliant, with the following Internationally recognised standards.
            </p>
          </div>

          <div className=" wow fadeInUp pb-3 text-center" data-wow-duration="1.5s">
            <div>
              <img src="assets/images/products/security.png" alt="" />
            </div>
          </div>
         

        </div>
      </section>


      
    </div>
  </Fragment>
}

export default HowWork