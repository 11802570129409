import React from 'react';
import Header from './Header'
// import Footer from './Footer'

import {
  Banners,
  Featured,
  HowWork,
  DocumentTeam,
  // Blog,
  // AboutUs
} from './Sections'

const {
  REACT_APP_PARTICLES
} = process.env


function App() {
  return (
    <div>
      <div className="preloader" id="preloader">
        <svg className="xs-preload" viewBox="0 0 120 120" width="120px" height="120px">
          <circle className="inner" cx={60} cy={60} r={32} />
          <circle className="middle" cx={60} cy={60} r={38} />
          <circle className="outer" cx={60} cy={60} r={44} />
        </svg>
        <a className="cencel-preloader" href>Cancel Preloader</a>
      </div>
      
      <div className="xs-sidebar-group info-group">
        <div className="xs-overlay xs-bg-black" />
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div className="widget-heading">
              <a href="#" className="close-side-widget">
                <i className="icon icon-cross" />
              </a>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-logo-wraper">
                <img src="assets/images/sidebar_logo_1.png" alt="sidebar logo" />
              </div>
              <p>Far far away, behind the word moun tains, far from the countries Vokalia and Consonantia, there
              live the blind texts. Separated they live in Bookmarksgrove right at the coast of </p>
              <ul className="sideabr-list-widget">
                <li>
                  <div className="media">
                    <div className="d-flex">
                      <i className="icon icon-placeholder2" />
                    </div>
                    <div className="media-body">
                      <p>759 Pinewood Avenue</p>
                      <span>Marquette, Michigan</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="media">
                    <div className="d-flex">
                      <i className="icon icon-email2" />
                    </div>
                    <div className="media-body">
                      <a href="mailto:info@domain.com">info@domain.com</a>
                      <span>Online Support</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="media">
                    <div className="d-flex">
                      <i className="icon icon-phone-call2" />
                    </div>
                    <div className="media-body">
                      <p>906-624-2565</p>
                      <span>Mon-Fri 8am-5pm</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="subscribe-from">
                <p>Get Subscribed!</p>
                <form action="#" method="POST" id="subscribe-form" className="subscribe-form">
                  <label htmlFor="sub-input" />
                  <div className="form-group">
                    <input type="email" name="email" id="sub-input" placeholder="Enter your mail here" className="form-control" />
                    <button className="sub-btn" type="submit"><i className="icon icon-arrow-right" /></button>
                  </div>
                </form>
              </div>
              <ul className="social-list version-2">
                <li><a href="#" className="facebook"><i className="fa fa-facebook" /></a></li>
                <li><a href="#" className="twitter"><i className="fa fa-twitter" /></a></li>
                <li><a href="#" className="linkedin"><i className="fa fa-linkedin" /></a></li>
                <li><a href="#" className="instagram"><i className="fa fa-instagram" /></a></li>
                <li><a href="#" className="vimeo"><i className="fa fa-vimeo" /></a></li>
              </ul>
              <div className="text-center">
                <a href="#contact_us" className="btn btn-primary">Purchase Now</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Header />


      <div id={REACT_APP_PARTICLES === 'true' ? 'particles-js' : null}>
        <Banners />
        {/* <AboutUs /> */}
        
      </div>
      <Featured />
      <HowWork />
      <DocumentTeam />
      <div className="blog-and-footer-area">
        {/* <Blog /> */}
        {/* <Footer /> */}
      </div>
      <div className="BackTo">
        <a href="#" className="fa fa-angle-up" aria-hidden="true" />
      </div>
    </div>
  );
}

export default App;
