import { Fragment } from "react";

function Banners() {
    return <Fragment>
        <section className="banner-sec" id="particles-js1">
            <div className="banner-item" style={{ background: 'url(assets/images/background/banner_bg.jpg) no-repeat center center /cover' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="banner-content">
                                <h1 className="banner-title">The Future Of Fintech</h1>
                                <p>
                                    Smart Solutions
                  </p>
                                <a href="#contact_us" className="btn btn-primary">Get A Quote</a>
                            </div>
                        </div>
                    </div>
                    <div className="banner-img-item">
                        <img className="banner-img" src="assets/images/banner/bg_1365x793.png" alt="Paymenta" />
                        <img className="banner-ico banner-ico-1" src="assets/images/banner/paymenta_57x64.png" alt="Paymenta" />
                        <img className="banner-ico banner-ico-2" src="assets/images/banner/paymenta_57x64.png" alt="Paymenta" />
                        <img className="banner-ico banner-ico-3" src="assets/images/banner/paymenta_72x82.png" alt="Paymenta" />
                        <img className="banner-ico banner-ico-4" src="assets/images/banner/paymenta_57x64.png" alt="Paymenta" />
                        <img className="banner-ico banner-ico-5" src="assets/images/banner/paymenta_57x64.png" alt="Paymenta" />
                    </div>
                </div>
            </div>
        </section>
    </Fragment>
}


export default Banners